import styled from "styled-components";
import { device } from '../../../theme'

export const SectionWrap = styled.section`
    background:${props => props.theme.colors.gray};
    padding-top: 60px;
    padding-bottom: 60px;
    @media ${device.medium}{
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @media ${device.small}{
        padding-top: 30px;
        padding-bottom: 30px;
    }
`;

export const AboutHeading = styled.div``;

export const AboutText = styled.div``;