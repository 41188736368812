import styled from 'styled-components'
import { device } from '../../../theme'

export const SectionWrap = styled.div`
    padding-top: 60px;
    padding-bottom: 60px;
    @media ${device.medium}{
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @media ${device.small}{
        padding-top: 30px;
        padding-bottom: 30px;
        .service-after{
            flex-direction:row-reverse;
        }
        .service-text.icon-after:before
        {
            left:-10px;
        }
    }
    .box-item{
        margin-bottom: 50px;
    }
    .service-box{
        padding:0;
        @media ${device.small}{
            padding-left:15px;
            padding-right:15px;
        }
        .service-list{
            display:flex;
            .service-image{
                width: 50%;
                height: 250px;
                text-align: center;
                img{height: 104px;margin-top: 73px;}
                @media ${device.small}{
                    height: auto;
                }
            }
            .service-text{
                display: flex;
                flex-direction: column;
                justify-content: center;
                line-height:1.5;
                width:50%;
                height:250px;
                padding:15px;
                position:relative;
                h5{
                   margin-bottom:5px;
               }
               p{
                   margin-bottom:10px;
               }
               a{
                   text-align:left;
               }
               text-align:left;
               @media ${device.small}{
                width:100%;
               height:auto;
            }
            }
            .service-text.icon-before,.service-text.icon-after{
                &:before{
                    position: absolute;
                    content: "";
                    width: 20px;
                    height: 20px;
                    border-top: 20px solid white;
                    border-right: 20px solid white;
                    transform: rotate(-45deg);
                    top: 45%;
                    z-index:5;
                }
                &:after{
                    position:absolute;
                    content:"";
                    background:#e44857d4;
                    width:0;
                    height:100%;
                    left:0;
                    top:0;
                    right:100%;
                    bottom:0;
                    transition: 0.5s;
                }
                &:hover{
                    &:after{
                        width:100%;
                        right:0;
                    }
                    &:before{
                        color:#e44857d4;
                        border-top: 20px solid #e86673;
                        border-right: 20px solid #e86673;
                    }
                    h5,p,a {
                        z-index:1;
                        color:#ffffff; 
                    }

                }
                
            }
            .service-text.icon-after{
                &:after{
                    left:100%;
                }
                &:hover{
                    &:after{
                        width:100%;
                        left:0;
                    }
                }
            }
            .service-text.icon-before.two{
                &:after{
                    background:#7d5ca6de;
                }
                &:hover{
                    &:before{
                        color:#7d5ca6de;
                        border-top: 20px solid #8d71b1;
                        border-right: 20px solid #8d71b1;
                    }
                }
            }
            .service-text.icon-before.three{
                &:after{
                    background:#085380d4;
                }
                &:hover{
                    &:before{
                        color:#085380d4;
                        border-top: 20px solid #317095;
                        border-right: 20px solid #317095;
                    }
                }
            }
            .serbgdigi{background-color: #e44857d4;}
            .serbgmob{background-color: #7d5ca6de;}
            .serbgana{background-color: #085380d4;}
            .serbgweb{background-color: #009898d4;}
            .serbgcloud{background-color: #6e205fd9;}
            .service-text.icon-before{
                &:before{
                    left: -10px !important;
                }
            }
            .service-text.icon-after{
                text-align:right;
                a{text-align:right;}
                &:before{
                    right: -10px !important;
                }
            }
            .service-text.icon-after.four{
                &:after{
                    background:#009898d4;
                }
                &:hover{
                    &:before{
                        color:#009898d4;
                        border-top: 20px solid #2ba9a9;
                        border-right: 20px solid #2ba9a9;
                    }
                }
            }
            .service-text.icon-after.five{
                &:after{
                    background:#6e205fd9;
                }
                &:hover{
                    &:before{
                        color:#6e205fd9;
                        border-top: 20px solid #834176;
                        border-right: 20px solid #834176;
                    }
                }
            }
        }
    }
`;

export const SectionBottom = styled.div`
    text-align:left;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.40;
    color: #333333;
`;