import styled from "styled-components";
import { device } from '../../../theme'

export const HeroWrapper = styled.section`
    overflow: hidden;
    background: #F6FAFE;
    
`;

export const HeroContent = styled.div`
    position: absolute;
    top:60%;
    left: 0;
    transform: translateY(-50%);
    z-index: 4;
    max-width: 100%;
    width: 100%;
    margin-left: auto;
    h3{
        font-weight: 800;
        font-size:64px;
        line-height:1;
    }
    @media ${device.xxlargeOnly}{
        img{margin-bottom:-65px}
    }
    @media ${device.medium}{
        h3{
            font-weight: 700;
            font-size:40px;
            line-height:1;
        }
        img{margin-bottom:-65px}
        padding-left: 15px;
        padding-right:15px;
    }
    @media ${device.small}{
        text-align:center;
        p{
            margin-bottom:0;
        }
        h3{
            font-weight: 500;
            font-size:38px;
            line-height:1;
        }
        padding-top: 50px;
        padding-left:0;
        // left: 16px;
    }
`;

export const HeroImagesWrap = styled.div`
    position: relative;
    padding-top: 0;
    z-index:1;
    max-height:100vh;
    @media ${device.small}{
        padding-top: 0;
        height:100vh;
    }
    &:before{
        position:absolute;
        content:"";
        top:0;
        left:0;
        right:0;
        bottom:0;
        background: rgba(0,21,45,.9);
        z-index: 3;
    }   
`;

export const HeroImageOne = styled.figure`
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 100%;
    @media ${device.large}{
        height: auto;
    }
`;

export const HeroImageTwo = styled.figure`

    @media ${device.small}
    {
        img{
            display:none;
        }
    }
    
    position: relative;
    z-index: 2;
    .gatsby-image-wrapper{
        margin:0 !important;
        margin-left:auto !important;
    } 
`;