import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import { MdTrendingFlat } from "react-icons/md";
import { Container, Row, Col } from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
import Button from '../../../components/ui/button'
import { SectionWrap, SectionBottom } from './services-area.style'

const OurServices = ({ bottomLinkStyle, sectionTitleStyle, btnStyle }) => {
    const servicesData = useStaticQuery(graphql`
        query CybersecurityServicesQuery {
            indexCybersecurityJson(id: {eq: "cybersecurity-service-content"}) {
                bottom_text
                bottom_link_text
                path
            }
            allItServicesJson(sort: {order: DESC, fields: id}, filter: {is_featured: {eq: false}}) {
                edges {
                  node {
                    fields{
                        slug
                    }
                    id
                    title
                    excerpt
                    image {
                      childImageSharp {
                        fluid(maxWidth: 480, maxHeight: 298, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                            presentationWidth
                            presentationHeight
                            aspectRatio
                        }
                      }
                    }
                  }
                }
            }
        }
    `);
    const { bottom_text, bottom_link_text, path } = servicesData.indexCybersecurityJson;
    const services = servicesData.allItServicesJson.edges;
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            {...sectionTitleStyle}
                            title="Our <span>services</span>"
                            subtitle="Proud Projects that Make Us Stand Out"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} style={{ padding: 0 }}>
                        <div className="service-box">
                            <div className="service-list">
                                <div className="service-image serbgdigi">
                                    <img src="../../../../../digital-transformation.svg" alt="digital-transformation"></img>
                                </div>
                                <div className="service-text icon-before one">
                                    <h5>Block Chain</h5>
                                    <p>Blockchain technology is a decentralized, distributed ledger that stores the record of ownership of digital assets. Any data stored on blockchain is unable to be modified, making the technology a legitimate disruptor for industries like payments, 
                                        cybersecurity and healthcare. Discover more on what it is,how it’s used and its history.</p>
                                    <Button to={"/blockchain"} icon={<MdTrendingFlat />} {...btnStyle}>Read More</Button>

                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} style={{ padding: 0 }}>
                        <div className="service-box">
                            <div className="service-list">
                                <div className="service-image serbgmob">
                                    <img src="../../../../mobile-apps.svg" alt="digital-transformation"></img>
                                </div>
                                <div className="service-text icon-before two">
                                    <h5>Mobile App</h5>
                                    <p>Delivering Innovative Mobile App Development Services possesses expertise in designing and developing intuitive mobile applications that fit with your business objectives.</p>
                                    <Button to={"/mobileapps"} icon={<MdTrendingFlat />} {...btnStyle}>Read More</Button>

                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} style={{ padding: 0 }}>
                        <div className="service-box">
                            <div className="service-list">
                                <div className="service-image serbgana">
                                    <img src="../../../../analytics-icon.svg" alt="digital-transformation"></img>
                                </div>
                                <div className="service-text icon-before three">
                                    <h5>Analytics</h5>
                                    <p>Can handle complex transformations, multi-step operations, aggregating data from different data sources or data types and can load in parallel to many multiple destinations.</p>
                                    <Button to={"/analytics"} icon={<MdTrendingFlat />} {...btnStyle}>Read More</Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} style={{ padding: 0 }}>
                        <div className="service-box">
                            <div className="service-list service-after">
                                <div className="service-text icon-after four">
                                    <h5>Modern Web Apps</h5>
                                    <p>Prioritizing content delivery to all browsers, and then progressively layer enhancements according to the device or browser capabilities.</p>
                                    <Button to={"/webapps"} icon={<MdTrendingFlat />} {...btnStyle}>Read More</Button>
                                </div>
                                <div className="service-image serbgweb">
                                    <img src="../../../../web-apps.svg" alt="digital-transformation"></img>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} style={{ padding: 0 }}>
                        <div className="service-box">
                            <div className="service-list service-after">
                                <div className="service-text icon-after five">
                                    <h5>Cloud</h5>
                                    <p>Rapidly modernize applications, migrate the right workloads, and securely manage your multi-cloud environment.</p>
                                    <Button to={"/cloud"} icon={<MdTrendingFlat />} {...btnStyle}>Read More</Button>

                                </div>
                                <div className="service-image serbgcloud">
                                    <img src="../../../../cloud.svg" alt="digital-transformation"></img>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

OurServices.propTypes = {
    bottomLinkStyle: PropTypes.object,
    sectionTitleStyle: PropTypes.object,
    sectionStyle: PropTypes.object
}

OurServices.defaultProps = {
    sectionTitleStyle: {
        mb: '40px',
        responsive: {
            small: {
                mb: '30px'
            }
        }
    },
    bottomLinkStyle: {
        ml: '5px',
        layout: 'underline',
        hover: {
            layout: 2
        }
    },
    btnStyle: {
        varient: 'texted',
        icondistance: '4px',
        align:'left'
    }
}

export default OurServices;