import styled from 'styled-components';
import {device} from '../../../theme'

export const SectionWrap = styled.div `
    padding-top: 60px;
    padding-bottom: 30px;
    background: #F8F8F8 url(../../../../banner.png);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media ${device.medium}{
        padding-top: 80px;
        padding-bottom: 45px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 25px;
    }
    .counter-style{font-size: 56px;
        font-weight: 400;
        line-height: 1;
        color: #f36d21;
        margin-bottom: 19px;
    }
    .kpi-style{font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #ffffff;
    }
    .kpi-div{text-align: center;
        margin-bottom: 30px;}
`;

export const HeaderWrap = styled.div `
    margin-right: 3rem;
    @media ${device.medium}{
        margin-bottom: 20px;
    }
`;