import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-three'
import Footer from '../containers/layout/footer/footer-one'
import HeroArea from '../containers/index-cybersecurity/hero-area'
import AboutArea from '../containers/index-cybersecurity/about-area'
import ServicesArea from '../containers/index-cybersecurity/services-area'
import FeaturesArea from '../containers/index-cybersecurity/features-area'
import FunFactArea from '../containers/index-resolutions/funfact-area'
import ContactInfoArea from '../containers/global/contact-area/contact-two'
import ClientsArea from '../containers/global/clients-area'

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Home" />
    <Header transparent/>
    <main className="site-wrapper-reveal">
      <HeroArea />
      <AboutArea />
      <ServicesArea />
      <FeaturesArea />
      <FunFactArea />
      <ClientsArea />
      {/* <ContactInfoArea /> */}
    </main>
    <Footer />
  </Layout>
)

export default IndexPage
